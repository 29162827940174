import React from 'react';
import classNames from "classnames";
import style from "../Portfolio/Portfolio.module.css";
import { Link } from "react-router-dom";
import ProjectImage from "../../components/ProjectImage/ProjectImage"
import ProjectImageGrid from "../../components/ProjectImageGrid/ProjectImageGrid";
import projectImageGridStyle from "../../components/ProjectImageGrid/ProjectImageGrid.module.css";
import CopyrightFooter from "../../components/CopyrightFooter/CopyrightFooter";
import styled from "styled-components";

const Footer = styled.footer`
    font-size: .75em;
    text-transform: lowercase;
    padding-top: 3em;
    padding-bottom: 3em;
    text-align: center;
`;

class Portfolio extends React.Component {

    render() {
        return (
            <div className={classNames(style.portfolio)}>
                <div className={style.content}>
                    <div className={style["help-text"]}>
                        Klik op de afbeeldingen voor meer
                    </div>
                    <ProjectImageGrid>
                        {this.props.projects.map(project => (
                            <div className={projectImageGridStyle["grid-image"]} key={project.key}>
                                <Link to={`${this.props.baseUrl}/portfolio/${project.key}`} title={project.title}>
                                    <ProjectImage clickable={true} src={project.thumb} />
                                </Link>
                            </div>
                        ))}
                    </ProjectImageGrid>
                </div>
                <Footer>
                    <CopyrightFooter className={style.footer} />
                </Footer>
            </div>
        );
    }

    componentDidMount() {
        document.title = "Portfolio • Studiyoo";
    }

}

export default Portfolio;