import React from 'react';

class CopyrightFooter extends React.Component {

    render() {
        return (
            <small>Copyright {new Date().getFullYear()} &copy; www.studiyoo.nl</small>
        );
    }

}

export default CopyrightFooter;