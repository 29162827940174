import React from 'react';
import style from "./ProjectImageGrid.module.css";
import classNames from "classnames";

class ProjectImageGrid extends React.Component {

    render() {
        return (
            <div className={classNames(this.props.className, style.grid)}>
                {this.props.children}
            </div>
        );
    }
}

export default ProjectImageGrid;