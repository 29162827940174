import React from 'react';
import { Route, BrowserRouter, Switch } from "react-router-dom";
import Home from "./pages/Home/Home";
import Portfolio from "./pages/Portfolio/Portfolio";
import Navigation from './components/Navigation/Navigation';
import Project from './pages/Project/Project';
import Error from './pages/Error/Error';
import { getCurrentTheme } from "./themes";
import Header from "./components/Header/Header";
import ResetScrollPosition from "./components/Functions/ResetScrollPosition";
import { ThemeProvider } from "styled-components";
import MenuContext from './AppContext';
import Contact from './pages/Contact/Contact';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            navigationVisible: false,
            toggleNavigation: this.toggleNavigation.bind(this),
            closeNavigation: this.closeNavigation.bind(this),
            projects: [],
            error: false,
            errorMessage: null
        };
    }

    render() {
        return <MenuContext.Provider value={this.state}>
            <ThemeProvider theme={getCurrentTheme()}>
                <BrowserRouter>
                    <ResetScrollPosition />

                    <Navigation baseUrl={this.props.baseUrl} />

                    <Switch>
                        <Route exact path={`${this.props.baseUrl}/`}>
                            <Header baseUrl={this.props.baseUrl} alternateTheme={false} floating={true} />
                        </Route>
                        <Route>
                            <Header baseUrl={this.props.baseUrl} />
                        </Route>
                    </Switch>

                    <Switch>
                        <Route exact path={`${this.props.baseUrl}/`}
                            render={() =>
                                <Home {...this.props} />
                            }
                        />
                        <Route exact path={`${this.props.baseUrl}/contact`}
                            render={() =>
                                <Contact {...this.props} />
                            }
                        />
                        <Route exact path={`${this.props.baseUrl}/portfolio`}
                            render={() =>
                                <Portfolio {...this.props} projects={this.state.projects} />
                            }
                        />
                        <Route exact path={`${this.props.baseUrl}/portfolio/:name`}
                            render={({ match }) =>
                                <Project {...this.props} projectName={match.params.name} />
                            }
                        />
                        <Route
                            render={() =>
                                <Error message={"Er is iets fout gegaan bij het laden van deze pagina. Mogelijk bestaat de pagina niet of is deze verwijderd."} {...this.props} />
                            }
                        />
                    </Switch>
                </BrowserRouter>
            </ThemeProvider>
        </MenuContext.Provider>;
    }

    handleKeyDown = (event) => {
        if (event.keyCode === 27) {
            this.closeNavigation();
        }
    };

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);

        fetch(this.props.backendUrl + "portfolio")
            .then(response => response.json())
            .then(data => {
                this.setState({
                    projects: data.projects.map(project => ({
                        title: project.title,
                        uri: this.props.backendUrl + "project/" + project.path,
                        thumb: data.base_url + "/" + project.path + "/" + project.thumb,
                        key: project.name
                    }))
                });
            });
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    toggleNavigation() {
        this.setState((state) => {
            return {
                navigationVisible: !state.navigationVisible,
                contactFormVisible: false
            }
        });
    }

    closeNavigation() {
        this.setState({
            navigationVisible: false
        });
    }

}

export default App;
