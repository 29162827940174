export const dark = "dark";
export const light = "light";

export class Theme {
    constructor(className, foreground, background, alternateForeground, alternateBackground) {
        this.className = className;
        this.foreground = foreground;
        this.background = background;
        this.alternateForeground = alternateForeground;
        this.alternateBackground = alternateBackground;
    }
}

export function getCurrentTheme() {
    return getThemeByName(window.theme);
}

export function getThemeByName(theme) {
    switch (theme) {
        case dark: return new Theme('themed-dark', '#ffffff', '#0C0B09', '#0C0B09', '#ffffff');
        default: return new Theme('themed-light', '#0C0B09', '#ffffff', '#0C0B09', '#ffffff');
    }
}
