import React from 'react';
import Button from "../../components/Button/Button";
import CopyrightFooter from "../../components/CopyrightFooter/CopyrightFooter";
import { ThemeConsumer } from "styled-components";
import styled from "styled-components";
import FormButton from "../../components/Button/FormButton";
import style from "./Contact.module.css";
import TextareaAutosize from 'react-textarea-autosize';
import { Helmet } from 'react-helmet';
import { useState } from "react"
import { useAsync } from "react-async"
import Spinner from '../../components/Spinner';

const Footer = styled.footer`
    font-size: .75em;
    text-transform: lowercase;
    padding-top: 3em;
    padding-bottom: 3em;
    text-align: center;
    color: #0C0B09;
`;

const Page = styled.div`
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column; 
    flex: 1;
    width: 100%;
    max-width: 720px;
    margin: auto;
`;

const Content = styled.div`
    margin: auto;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: 1536px) {
        padding: 28px;
    }

    @media (max-width: 1080px) {
        padding: 24px;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2em;
`;

const Input = styled.input`
    background-color: #F3F1F2;
    border: 0;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    padding: 1.4em;
    display: block;
    margin-bottom: .6666em;
`;

const TextArea = styled(TextareaAutosize)`
    background-color: #F3F1F2;
    border: 0;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    padding: 1.4em;
    display: block;
    margin-bottom: .6666em;
    resize: none;
`;

const Header = styled.header`
    font-size: 3em;
    line-height: 1.5em;
    white-space: nowrap;

    @media( max-width: 400px) {
        font-size: 2.5em;
    }
`;

const HeaderTagLine = styled.span`
    margin-bottom: 1em;
`;

const Paragraph = styled.p`
    margin-top: 2.8em;
    margin-bottom: 1.4em;
    line-height: 1.5em;
    max-width: 25em;
`;

function Contact(props) {
    const form = React.createRef();
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const submitForm = ([body], _, { signal }) => {
        return fetch(props.backendUrl + "contact", {
            method: 'POST',
            body: body
        })
            .then(response => response.json())
            .then(data => {
                if (!data) {
                    throw new Error("No data in response.");
                }
                if (!data.success) {
                    if (data.error) {
                        throw data.error;
                    }

                    throw new Error("Unknown error occurred.");
                }
                return data;
            });
    };
    const { isPending, data, error, run } = useAsync({ deferFn: submitForm })
    const handleSubmit = event => {
        event.preventDefault()
        run(new FormData(form.current))
    }

    console.log(isPending, data, error);

    return (
        <>
            <Helmet>
                <title>Contact • Studiyoo</title>
            </Helmet>
            <ThemeConsumer>
                {theme => (
                    <Page>
                        <Content>
                            {!isPending && !(data && data.success) && <>
                                <Header>'n berichtje</Header>
                                <HeaderTagLine>naar info@studiyoo.nl</HeaderTagLine>
                                {error && <Paragraph>
                                    Het formulier kon niet worden verzonden.
                                </Paragraph>}
                                <Form ref={form} method="POST" action={props.backendUrl + "contact"} onSubmit={handleSubmit}>
                                    <Input required name="name" type="text" value={name} placeholder="Naam" onChange={e => setName(e.target.value)} />
                                    <Input required name="email" type="email" value={email} placeholder="Email" onChange={e => setEmail(e.target.value)} />
                                    <TextArea required name="message" minRows={10} value={message} placeholder="Bericht" onChange={e => setMessage(e.target.value)} />
                                    <FormButton className={style.button} text="Verstuur" />
                                </Form>
                            </>}
                            {isPending && <>
                                <Spinner fill="#0C0B09" />
                            </>}
                            {data && data.success && <>
                                <Header>'t is gelukt</Header>
                                <Paragraph>Bedankt voor je bericht, hij ligt hier op de mat. Je ontvangt zo snel mogelijk reactie.</Paragraph>
                                <Button className={style.button} link={`${process.env.PUBLIC_URL}/portfolio`} text="Bekijk portfolio" />
                            </>}
                        </Content>
                        <Footer>
                            <CopyrightFooter />
                        </Footer>
                    </Page>
                )}
            </ThemeConsumer>
        </>
    );
}

export default Contact;