import classNames from "classnames";
import React from "react";
import style from './Button.module.css';

class FormButton extends React.Component {

    render() {
        return (
            <button {...this.stateprops} className={classNames(style.button, this.props.className)}>
                <span>
                    {this.props.text}
                </span>
            </button>
        );
    }

}

export default FormButton;