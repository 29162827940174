import React from 'react';
import style from './Home.module.css';
import classNames from "classnames";
import Button from "../../components/Button/Button";
import { ThemeConsumer } from "styled-components";

const coverUrl = process.env.PUBLIC_URL + "/style/images/wallpaper-home.jpg";

class Home extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            coverLoaded: false
        }
    }

    render() {
        return (
            <ThemeConsumer>
                {theme => (
                    <div className={classNames(style.home, style[theme.className])} >
                        <div className={classNames(style.background, {
                            [style.loaded]: this.state.coverLoaded
                        })} style={{
                            "backgroundImage": "url(" + coverUrl + ")"
                        }} />
                        <div className={style.content}>
                            <img className={style["slogan-vector"]}
                                alt="Slogan"
                                src={process.env.PUBLIC_URL + "/style/images/slogan-vector.svg"} />
                            <Button link={`${process.env.PUBLIC_URL}/portfolio`}
                                className={classNames(style["portfolio-button"], style[theme.className], {
                                })} text="Bekijk de projecten" />
                        </div>
                    </div>
                )}
            </ThemeConsumer>
        );
    }

    componentDidMount() {
        document.title = "Home • Studiyoo";

        window.scrollTo(0, 0);

        let img = new Image();
        img.src = coverUrl;
        img.onload = () => {
            this.setState({
                coverLoaded: true
            });
            img = null;
        };
    }

}

export default Home;