import React from 'react';
import { keyframes } from 'styled-components';
import styled from 'styled-components';

const spinAnimation = keyframes`
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
`;

const SpinnerSvg = styled.svg`
    position: absolute;
    z-index: 0;
    content: "";
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -24px;
    margin-top: -24px;
    width: 48px;
    height: 48px;
    animation-name: ${spinAnimation};
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
`;

class Spinner extends React.Component {

    render() {
        return <SpinnerSvg xmlns="http://www.w3.org/2000/svg" viewBox="-2.46 53.45 28.35 24.09">
            <g fill={this.props.fill}>
                <path d="M11.72 54.47h-.25v1.38h.25c5.32 0 9.64 4.33 9.64 9.64s-4.33 9.64-9.64 9.64c-5.32 0-9.64-4.33-9.64-9.64 0-3.61 1.99-6.89 5.2-8.56l.21-.11-.44-1.01-.07-.23-.31.12C3 57.6.71 61.36.71 65.49c0 6.08 4.94 11.02 11.02 11.02s11.02-4.94 11.02-11.02-4.96-11.02-11.03-11.02z" />
                <path d="M14.67 63.93h4.04l-.04-.29c-.07-.46-.26-.87-.36-1.08l-.07-.1c-.51-1.1-1.25-2.03-2.14-2.72-1.27-.96-2.78-1.47-4.37-1.47-3.98 0-7.22 3.24-7.22 7.22s3.24 7.22 7.22 7.22c1.91 0 3.71-.74 5.07-2.08l.18-.18-1-.95-.17.17c-1.1 1.07-2.55 1.66-4.08 1.66-3.22 0-5.85-2.62-5.85-5.85s2.62-5.85 5.85-5.85c2.01 0 3.86 1.05 4.93 2.78h-1.98v1.52z" />
            </g>
        </SpinnerSvg>;
    }


}

Spinner.defaultProps = {
    fill: "#FFFFFF"
}

export default Spinner;