import React from 'react';
import style from "./ProjectImage.module.css";
import classNames from 'classnames';
import Spinner from '../Spinner';

class ProjectImage extends React.Component {

    constructor(props) {
        super(props);

        this.onLoad = this.onLoad.bind(this);

        this.state = {
            loaded: false
        };
    }

    render() {
        return (
            <div className={classNames(style.wrapper, {
                [style["is-ref"]]: this.props.clickable,
                [style.loaded]: this.state.loaded
            })}>
                <Spinner fill="#FFFFFF"/>
                <img loading="lazy" onLoad={this.onLoad} className={classNames(this.props.className)} alt={this.props.title} src={this.props.src} />
            </div>
        );
    }

    onLoad() {
        this.setState({ loaded: true });
    }

}

export default ProjectImage;