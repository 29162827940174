import classNames from "classnames";
import {Link} from "react-router-dom";
import React from "react";
import style from './Button.module.css';

class Button extends React.Component {

    render() {
        return (
            <Link to={this.props.link} className={classNames(style.button, this.props.className)}>
                <span>
                    {this.props.text}
                </span>
            </Link>
        );
    }

}

export default Button;