import React from 'react';
import {withRouter} from "react-router-dom";

class ResetScrollPosition extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            'positions': {}
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (this.state.positions[this.props.location.pathname] !== undefined)
                window.scrollTo(0, this.state.positions[this.props.location.pathname]);
            else
                window.scrollTo(0, 0);
        }
    }

    componentWillReceiveProps(nextProps) {
        const pos = this.getScrollPosition(window);

        this.setState((state, props) => {
            state.positions[this.props.location.pathname] = pos;

            return {
                positions: state.positions
            }
        })
    }

    getScrollPosition(target) {
        if (target instanceof window.Window) {
            return target.scrollY;
        }

        return target.scrollTop;
    }

    render() {
        return null;
    }
}

export default withRouter(ResetScrollPosition);