import React from 'react';
import style from './Error.module.css';
import classNames from "classnames";
import Button from "../../components/Button/Button";
import CopyrightFooter from "../../components/CopyrightFooter/CopyrightFooter";
import { ThemeConsumer } from "styled-components";
import styled from "styled-components";

const Footer = styled.footer`
    font-size: .75em;
    text-transform: lowercase;
    padding-top: 3em;
    padding-bottom: 3em;
    text-align: center;
    color: #0C0B09;
`;

class Error extends React.Component {

    render() {
        return (
            <ThemeConsumer>
                {theme => (
                    <div className={classNames(style.error, style[theme.className])}>
                        <div className={style.content}>
                            <span className={style["error-message"]}>{this.props.message}</span>
                            <Button link={`${process.env.PUBLIC_URL}/`}
                                className={classNames(style["home-button"], style[theme.className], {
                                })} text="Terug naar home" />
                        </div>
                        <Footer>
                            <CopyrightFooter className={style.footer} />
                        </Footer>
                    </div>
                )}
            </ThemeConsumer>
        );
    }

    componentDidMount() {
        document.title = "Fout • Studiyoo";
    }

}

export default Error;