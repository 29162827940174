import React from 'react';
import ReactDOM from 'react-dom';
import './reset.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
}

ReactDOM.render(<App baseUrl={process.env.PUBLIC_URL} backendUrl={process.env.REACT_APP_BACKEND_API_URL}/>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
