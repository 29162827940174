import React from 'react';
import { Link } from "react-router-dom";
import CopyrightFooter from "../CopyrightFooter/CopyrightFooter";
import style from './Navigation.module.css'
import classNames from 'classnames';
import { ThemeConsumer } from "styled-components";
import MenuContext from '../../AppContext';
import styled from "styled-components";

const Footer = styled.footer`
    font-size: .75em;
    padding-top: 3em;
    padding-bottom: 3em;
`;

class Navigation extends React.Component {

    render() {
        return (
            <MenuContext.Consumer>
                {({ navigationVisible, toggleNavigation }) => (
                    < ThemeConsumer >
                        { theme => (
                            <div className={classNames(style.menu, this.props.className, style[theme.className], {
                                [style["is-visible"]]: navigationVisible
                            })}>
                                <nav className={style.navigation}>
                                    <ul>
                                        <li>
                                            <Link onClick={toggleNavigation} to={`${this.props.baseUrl}/`}>
                                                <span>Home</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={toggleNavigation} to={`${this.props.baseUrl}/portfolio`}>
                                                <span>Portfolio</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={toggleNavigation} to={`${this.props.baseUrl}/contact`}>
                                                <span>Contact</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                                <Footer>
                                    <CopyrightFooter className={style.footer} />
                                </Footer>
                            </div>
                        )}
                    </ThemeConsumer>
                )}
            </MenuContext.Consumer>
        );
    }

}


export default Navigation;