import React from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";

const BackButtonLink = styled(Link)`
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    z-index: 1000;
    height: 48px;
    width: 48px;
`;

const Icon = styled.svg`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity .3s , fill .3s ease-in-out;
    width: 32px;
`;

class BackButton extends React.Component {
    render() {
        return (
            <BackButtonLink to={this.props.link} title="Terug">
                <Icon xmlns="http://www.w3.org/2000/svg" viewBox="-2.46 34.18 28.35 24.1">
                    <path fill="#0C0B0A" d="M23.71 45.23H3.4l7.49-7.49.09-.09-1.34-1.34-8.58 8.58-1.33 1.34 1.33 1.34 8.48 8.48.1.1 1.34-1.34-7.58-7.58h20.31z" />
                </Icon>
            </BackButtonLink>
        );
    }

}

export default BackButton;