import React from 'react';
import classNames from "classnames";
import style from "../Project/Project.module.css";
import ProjectImage from "../../components/ProjectImage/ProjectImage"
import ProjectImageGrid from "../../components/ProjectImageGrid/ProjectImageGrid";
import projectImageGridStyle from "../../components/ProjectImageGrid/ProjectImageGrid.module.css";
import CopyrightFooter from "../../components/CopyrightFooter/CopyrightFooter";
import { ThemeConsumer } from "styled-components";
import styled from "styled-components";

const Footer = styled.footer`
    font-size: .75em;
    text-transform: lowercase;
    padding-top: 3em;
    padding-bottom: 3em;
    text-align: center;
`;

class Project extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            images: [],
            error: false
        };
    }

    render() {
        return (
            <ThemeConsumer>
                {theme => (
                    <div className={classNames(style.portfolio, style[theme.className])}>
                        <div className={style.content}>
                            <ProjectImageGrid>
                                {this.state.images.map(image => (
                                    <div className={projectImageGridStyle["grid-image"]} key={image.key}>
                                        <ProjectImage title={image.name} src={image.uri} />
                                    </div>
                                ))}
                            </ProjectImageGrid>
                        </div>
                        <Footer>
                            <CopyrightFooter className={style.footer} />
                        </Footer>
                    </div>
                )}
            </ThemeConsumer>
        );
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        fetch(this.props.backendUrl + "project/" + this.props.projectName)
            .then(response => response.json())
            .then(data => {
                document.title = data.title + " • Studiyoo";

                this.setState({
                    images: data.images.map(image => ({
                        name: data.title,
                        uri: data.base_url + "/" + data.path + "/" + image,
                        key: image
                    }))
                });
            })

    }

}

export default Project;