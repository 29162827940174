import React from 'react';
import styled from 'styled-components';
import Logo from "./Logo";
import MenuButton from "./MenuButton";
import BackButton from "./BackButton";
import { Switch, Route } from 'react-router';

const Header = styled.div`
padding: 32px;
display: flex;
justify-content: space-between;
align-items: center;
transition: background-color .3s ease-in;
background-color: ${props => props.alternateTheme ? props.theme.alternateBackground : props.theme.background};
@media (max-width: 1536px) {
    padding: 28px;
}
@media (max-width: 1080px) {
    padding: 24px;
}
`;

const FloatingHeader = styled(Header)`
position: absolute;
left: 0;
right: 0;
top: 0;
`;

class HeaderView extends React.Component {

    render() {
        const HeaderElement = this.props.floating ? FloatingHeader : Header;

        return (
            <HeaderElement {...this.props}>
                <Logo baseUrl={this.props.baseUrl} alternateTheme={this.props.alternateTheme} />
                <Switch>
                    <Route exact path={`${this.props.baseUrl}/portfolio/:name`}>
                        <BackButton link={`${process.env.PUBLIC_URL}/portfolio`} />
                    </Route>
                    <Route>
                        <MenuButton alternateTheme={this.props.alternateTheme} />
                    </Route>
                </Switch>
            </HeaderElement>
        );
    }

}

HeaderView.defaultProps = {
    alternateTheme: true
};

export default HeaderView;