import React from 'react';
import NavigationContext from '../../AppContext';
import styled from 'styled-components';

const MenuButtonButton = styled.button`
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    z-index: 1000;
    height: 48px;
    width: 48px;
`;

const MenuButtonIcon = styled.svg`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity .3s , fill .3s ease-in-out;
    fill: ${props => props.navigationVisible
        ? props.theme.foreground
        : props.alternateTheme
            ? props.theme.alternateForeground
            : props.theme.foreground};
`;

const MenuButtonIconOpen = styled(MenuButtonIcon)`
opacity:${props => !props.navigationVisible ? 1 : 0};
`;

const MenuButtonIconClose = styled(MenuButtonIcon)`
opacity:${props => props.navigationVisible ? 1 : 0};
`;

class MenuButton extends React.Component {

    render() {
        return (
            <NavigationContext.Consumer>
                {({ navigationVisible, toggleNavigation }) =>
                    <MenuButtonButton onClick={toggleNavigation} title="Menu">
                        <MenuButtonIconOpen
                            alternateTheme={this.props.alternateTheme}
                            navigationVisible={navigationVisible}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24.1"
                            height="16.290001">
                            <path d="M1.9e-7 2.12V0h24.1v2.12zM1.9e-7 9.21V7.08h24.1v2.13zM1.9e-7 16.29v-2.12h24.1v2.12z" />
                        </MenuButtonIconOpen>
                        <MenuButtonIconClose
                            alternateTheme={this.props.alternateTheme}
                            navigationVisible={navigationVisible}
                            xmlns="http://www.w3.org/2000/svg"
                            width="21.68"
                            height="21.68">
                            <path d="M21.57 1.57l.11-.11L20.21 0l-9.38 9.38L1.57.1 1.46 0 0 1.46l9.38 9.38L0 20.22l1.46 1.46 9.37-9.38 9.28 9.28.1.1 1.47-1.46-9.38-9.38z" />
                        </MenuButtonIconClose>
                    </MenuButtonButton>
                }
            </NavigationContext.Consumer>
        );
    }

}

export default MenuButton;